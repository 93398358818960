<template>
  <img
    :src="imgUrl"
    :width="size"
    :height="size"
    class="photo-icon"
    :title="title"
  />
</template>

<script lang="ts">
import { adjustColor, getAvatarURL } from '@/utils/utils';

export default {
  name: 'HMMemberIcon',
  props: {
    icon: {
      type: String,
    },
    fullName: {
      type: String,
    },
    color: {
      type: String,
    },
    fontSize: {
      type: Number,
      default: 12,
    },
    size: {
      type: String,
      default: '25px',
    },
    title: {
      type: String,
      default: '',
    },
  },
  computed: {
    background() {
      return adjustColor(this.color, 20);
      //return adjustColor(this.color, 20)
    },
    imgUrl() {
      return (
        this.icon ||
        getAvatarURL(
          this.fullName,
          this.color,
          'white',
          this.fontSize,
          this.size
        )
      );
    },
  },
};
</script>

<style scoped>
.photo-icon {
  vertical-align: center;
  object-fit: cover;
  border-radius: 50%;
}
</style>
